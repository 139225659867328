<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="ship-dialog"
        attach-to-body
        :class="`${showTab}_dialog`"
        v-bind="$attrs"
        @confirm="()=>{
            this.$emit('confirm')
        }"
        @close="()=>{
            this.$emit('close')
        }"
        @cancel="()=>{
            this.$emit('cancel')
        }"
    >
        <template #title>
            <slot name="title">
                <div class="header-title" :class="`${showTab}_title`">
                    <div class="id-role">
                        {{ title }}
                    </div>
                    <img
                        :src="popupRight"
                        class="pop-right"
                        alt=""
                        :width="180"
                    />
                </div>
            </slot>
        </template>
        <slot></slot>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </el-dialog>
</template>
<script>
import { getTextWidth } from "@/utils";
export default {
    name: "ShipDialog",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "船舶信息",
        },
        showTab: {
            type: String,
            default: "NB",
        },
        size: {
            type: String,
            default: "large",
        },
    },
    data() {
        return {
            popupRight: require("@/assets/img/login/pupup_right.png"),
            textWidth: 0,
        };
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            },
        },
    },
    watch: {
        title: {
            immediate: true,
            handler(val) {
                this.textWidth = getTextWidth(val, 24);
            },
        },
    },
};
</script>
<style scoped lang="stylus">
.ship-dialog{
    .header-title{
        background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
        height:40px;
        border-radius:10px 10px 0 0;
        position:relative;
        .id-role{
            position:absolute;
            color:white;
            font-size:16px;
            font-weight:bold;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .pop-right{
            position:absolute;
            right:30px;
        }
    }
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/.el-dialog__header {
        padding: 0
      }
      /deep/.el-dialog__body {
        text-align: initial;
        padding: 20px 25px 20px;
        border-radius: 10px;
        margin-top: -15px;
      }
      /deep/ .el-dialog__headerbtn{
        top:10px;
        font-size: 24px;
      }
       /deep/.el-dialog__close{
        color:#fff !important;
      }
}

.NB_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--RED-C11C20), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F)) !important;
    }
}

.SH_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--BLUE-006699), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--BLUE-3366FF), var(--BLUE-5493f4), var(--BLUE-006699)) !important;
    }
}

.QD_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--GREEN-003300), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--GREEN-006600), var(--GREEN-336600), var(--GREEN-003300)) !important;
    }
}

.TJ_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--YELLOW-FF9900), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--YELLOW-FF9900), var(--YELLOW-CC9966), var(--YELLOW-CC9933)) !important;
    }
}

.XM_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--PURPLE-330099), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--PURPLE-330099), var(--PURPLE-330066), var(--PURPLE-000066)) !important;
    }
}

.SZ_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--SZ-ORANGE-FIRST), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image:linear-gradient(var(--SZ-ORANGE-FIRST), var(--SZ-ORANGE-SECOND),var(--SZ-ORANGE-SECOND)) !important;
    }
}
</style>

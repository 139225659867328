<template>
    <div class="check-node-warp">
        <div class="check-node-header">
            <div class="check-node-header-left">
                <div class="check-node-header-row" v-for="item in basicData">
                    {{ item.label }}：{{ item.value }}
                    <template v-if="item.blockNum">
                        <div v-for="blockNum in item.blockNum" :key="blockNum">
                            &nbsp;
                        </div>
                        {{ item.blockLabel }}
                    </template>
                </div>
            </div>
            <div class="check-node-header-right">
                <el-popover
                    placement="top"
                    trigger="manual"
                    v-model="subscribePopVisible"
                    :close-delay="500"
                    :open-delay="500"
                >
                    <div
                        :class="`repeat_pop_content ${
                            subscribeSuccess ? 'success' : 'error'
                        }`"
                    >
                        {{ subscribePopContent }}
                    </div>
                    <div
                        slot="reference"
                        class="btn-subscribe"
                        @click="toSubscribe"
                    >
                        订阅
                    </div>
                </el-popover>

                <div class="location-ship" @click="toWhereShip">
                    <el-icon
                        name="location"
                        style="color: rgb(64, 158, 255)"
                    ></el-icon>
                    船在哪
                </div>
            </div>
        </div>
        <div class="check-node-body">
            <el-collapse>
                <el-steps
                    direction="vertical"
                    align-center
                    finish-status="success"
                    :active="activeIndex"
                >
                    <el-step
                        v-for="(item, index) in collpaseData"
                        icon=""
                        :class="!item.data.length && 'disabled-collapse'"
                        :key="index"
                    >
                        <template #description>
                            <el-collapse-item
                                :disabled="!item.data.length"
                                :class="'step-collapse'"
                                :title="
                                    !item.data.length
                                        ? `${item.title} 暂无信息`
                                        : item.title
                                "
                                :name="index"
                            >
                                <el-table
                                    border
                                    stripe
                                    v-if="item.data.length"
                                    :data="formatSubData(item.data)"
                                    style="max-width: 500px"
                                    :header-cell-style="{
                                        background: '#eef1f6',
                                        color: '#606266',
                                    }"
                                >
                                    <el-table-column
                                        v-for="subItem in item.data"
                                        :prop="subItem.name"
                                        :label="subItem.label"
                                        :key="subItem.name"
                                    ></el-table-column>
                                </el-table>
                            </el-collapse-item>
                        </template>
                    </el-step>
                </el-steps>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import { logisticsRequest } from "@/views/Logistics/logistics";
import { orderBoxTicket } from "@/api/tracking";
import { getCompanyId, getRole } from "@/utils/auth";
import { formatterTime } from "@/utils/validate";

import { wharfCodeToStr } from "@/utils";
// basicData:[
//     {label:'提单号',value:'ONEYNB4JH3076900'},
// {label:'卸货港',value:'cava2 船名航次：SAN DIEGO BRIDGE/073E'}]
export default {
    data() {
        return {
            subscribePopVisible: false,
            subscribePopContent: "",
            subscribeSuccess: false,
            boxStatus: [],
        };
    },
    props: {
        // 提单号
        blno: {
            type: String,
            default: "",
        },
        basicData: {
            type: Array,
            default: () => [],
        },
        collpaseData: {
            type: Array,
            default: () => [],
        },
        activeIndex: {
            type: Number,
            default: 0,
        },
        subscribeData: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        async toSubscribe() {
            try {
                const resUrl = await logisticsRequest({
                    tab: "NB",
                    type: 4,
                });
                // subscribeData:blno vesselsName voyage
                const res = await orderBoxTicket(
                    resUrl,
                    {
                        ...this.subscribeData,
                        role: getRole(),
                        companyId: getCompanyId(),
                        code: "NB",
                        isCheck: true,
                        psType: "1",
                    },
                    {
                        showErrorMessage: false,
                    },
                );
                this.subscribePopVisible = true;
                this.subscribePopContent = "订阅成功";
                this.subscribeSuccess = true;
                console.log("res", res);
            } catch (err) {
                this.subscribePopVisible = true;
                this.subscribePopContent = err.message;
                this.subscribeSuccess = false;
            } finally {
                setTimeout(() => {
                    this.subscribePopVisible = false;
                    this.$nextTick(() => {
                        this.subscribePopContent = "";
                    });
                }, 1500);
            }
        },
        formatSubData(data) {
            let temp = {};
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                // item.name;
                temp[item.name] = item.value;
            }
            return [temp];
        },
        toWhereShip() {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    blno: this.subscribeData.blno,
                    vesselEname: this.subscribeData.vesselsName,
                    voyage: this.subscribeData.voyage,
                },
                params: { item: this.subscribeData },
            });
            window.open(newpage.href, "_blank");
        },
        BoxStatus(data) {
            this.boxStatus = [];
            var nowD = new Date(); //当前时间

            if (data.sailingTime && data.sailingTime != "-") {
                var endD = new Date(data.sailingTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已离泊");
                }
            } else if (data.loadTime && data.loadTime != "-") {
                var endD = new Date(data.loadTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已装船");
                }
            } else if (data.pass_flag != "") {
                if (data.pass_flag == "已放行")
                    this.boxStatus.push("码头已放行");
                else if (data.pass_flag == "不能放行")
                    this.boxStatus.push("码头不能放行");
                else if (data.pass_flag == "取消放行")
                    this.boxStatus.push("码头取消放行");
            } else if (data.custom_flag == "Y") {
                this.boxStatus.push("海关已放行");
            } else if (data.arrivalTime && data.arrivalTime != "-") {
                var endD = new Date(data.arrivalTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已进港");
                } else {
                    this.boxStatus.push("未进港");
                }
            } else {
                this.boxStatus.push("未进港");
            }
        },
        cppNppTemp2(data) {
            let temp2 = {
                blno: this.blno,
                cpcode_flag: "",
                ifcsum_flag: "",
                custom_flag: "",
                sld_flag: "",
                ctnNo: "",
                cpcode: "",
                sizetype: "",
                pass_flag: "",
                remark: "",
                grossWeight: "",
                packageNum: "",
                boxCusmov: "",
                ctnNo: "",
                sealNo: "",
                arrivalTime: formatterTime(""),
                loadTime: formatterTime(""),
                sailingTime: formatterTime(""),
                Vesselnamevoyage: "",
                dischargeport: "",
            };
        },
    },
};
</script>
<style lang="stylus" scoped>
.repeat_pop_content{
    text-align: center;
    color: #F56C6C;
    font-size: 14px;
    font-weight: bold;
}
.success{
    color: #67C23A;
}
.error{
    color: #F56C6C;
}
.check-node-warp{
    .check-node-header{
        background-color: #f5f7fa;
        padding: 1.2rem 1.8rem;
        font-size: 1.2rem;
        display: flex;

        .check-node-header-left{
            line-height: 2rem;
            // min-width: 245px;
            .check-node-header-row{
                margin-bottom: 5px;
            }
        }
        .check-node-header-right{
            margin-left: auto;
            text-align: center;
            .btn-subscribe{
                // margin-right: 1rem;
                margin-bottom: 5px;
                padding: 0.5rem 1rem;
                background-color: rgb(255, 85, 31);
                color: #fff;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
    .check-node-body{
        padding: 1.2rem 1.8rem;
    }
    .step-collapse {
        // margin-left: 2.5rem;
    }
    /deep/.el-step__icon-inner{
        display:none;
    }
    /deep/.el-step {
        .el-step__head{
            top: 16px;
        }
        // &:last-of-type .el-step__description{
        //     padding-right: 10%;
        // }
    }
    /deep/.el-steps {
        .el-step__description:last-child{
            padding-right: 10%;
        }
    }
    /deep/.disabled-collapse{
        .is-success{
            border-color: #ccc;
            // .is-text{
            //     background:#fff;
            // }
        }
    }
    // /deep/.is-success{
    //     .is-text{
    //         background: #67C23A;
    //     }
    // }
}
</style>
